import React, {useCallback, useEffect, useState} from 'react';
import get from 'lodash/get';
import {Routes, Route, useLocation} from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import i18n from 'i18next';
import {useDispatch, useSelector} from 'react-redux';

import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import CssBaseline from '@mui/material/CssBaseline';

import RouteContextWrapper from 'contexts/route';
import MediaContextWrapper from 'contexts/media';
import CryptoContextWrapper from 'contexts/crypto';

import {LANGUAGE_LIST} from 'appRedux/actions/admin';
import {INSTANCE_SETTINGS_LIST, INSTANCE_SETTING_FILES} from 'appRedux/actions/instance';
import {ORGANIZATION_BASE_INFORMATION} from 'appRedux/actions/auth';
import {RootReducer} from 'appRedux/reducers';
import {GET_AVATAR, GET_USER_INFO} from 'appRedux/actions/profile';

import AlertContextWrapper from 'contexts/alert';

import AgentScreen from 'components/AgentScreenComponents/AgentScreen';
import ApiComponent from 'components/ApiComponent/ApiComponent';
import ClientScreenWrapper from 'components/ClientScreenComponents/ClientScreenWrapper/ClientScreenWrapper';
import {PrivateRoute} from 'components/index';
import UpdateWatcher from 'components/UpdateWatcher/UpdateWatcher';

import StartInvite from 'pages/start/invite/InviteScreen';
import Login from 'pages/start/login/LoginScreen';
import LoginNoSso from 'pages/start/login/LoginNoSsoScreen';
import LoginRedirect from 'pages/start/login/LoginRedirect';
import Start from 'pages/start/registration/StartScreen';
import Verification from 'pages/start/verification/Verification';
import ForgetNickname from 'pages/start/forgetNickname/ForgetNickname';
import ResetPassword from 'pages/start/resetPassword/ResetPassword';
import RecoveryPassword from 'pages/start/recoveryPassword/RecoveryPassword';
import ImprintPage from 'pages/start/imprint/ImprintPage';
import PrivacyPolicyPage from 'pages/start/privacyPolicy/PrivacyPolicy';
import LoginSso from 'pages/start/loginSso/LoginSso';
import AuthSso from 'pages/start/loginSso/AuthSso';
import EmailUpdatePage from 'pages/start/emailUpdate/EmailUpdatePage';

import PrintView from 'pages/agent/print/PrintView';
import PdfExportView from 'pages/agent/pdfExport/PdfExportView';

import GeneralPage from 'pages/client/general/GeneralPage';
import NotFound from 'pages/start/404/NotFound';
import ClientFinalPage from 'pages/client/final/FinalPage';
import ClientStartPage from 'pages/client/requests/ClientStartPage';
import ClientFormsListForRequesterPage from 'pages/client/formsList/ClientFormsListForRequesterPage';
import ClientChatPage from 'pages/client/chat/ClientChatPage';
import RequesterCasePage from 'pages/client/form/RequesterCasePage';
import CaseKeysGeneration from 'pages/client/keysGenerate/CaseKeysGeneration';
import Redirect from 'pages/start/redirect/Redirect';
import AccountSettingsPage from 'pages/common/account/AccountSettingsPage';
import SecurityPage from 'pages/common/security/SecurityPage';
import SelectDefaultRolePage from 'pages/common/defaultRole/SelectDefaultRolePage';
import RegistrationSso from 'pages/start/registrationSso/RegistrationSso';
import FormFaqPage from 'pages/client/formFaq/FormFaqPage';

import {
    LOCAL_STORAGE_CURRENT_LANGUAGE,
    LOCAL_STORAGE_LOGOUT_TIME,
    LOCAL_STORAGE_PERSIST_ROOT,
    LOCAL_STORAGE_REFRESH_TOKEN,
    LOCAL_STORAGE_TOKEN,
} from 'services/localStorage';

import {getBrowserLanguage} from 'helpers/language';
import {getExpirationAndCurrentTimeDifference} from 'helpers/menuHelper';
import {isDebug, LOCAL_STORAGE_DEBUG_MODE} from 'helpers/debug';

import {userRoles, routes, paths} from 'config/index';

const App = () => {
    const dispatch = useDispatch();

    const getAvatar = useCallback(() => dispatch({type: GET_AVATAR.REQUEST}), [dispatch]);
    const getLanguageList = useCallback(() => dispatch({type: LANGUAGE_LIST.REQUEST}), [dispatch]);
    const getInstanceSettings = useCallback(() => dispatch({type: INSTANCE_SETTINGS_LIST.REQUEST}), [dispatch]);
    const getInstanceFileNames = useCallback(() => dispatch({type: INSTANCE_SETTING_FILES.REQUEST}), [dispatch]);
    const getOrganizationInformation = useCallback(
        () => dispatch({type: ORGANIZATION_BASE_INFORMATION.REQUEST}),
        [dispatch],
    );
    const clearProfile = useCallback(() => dispatch({type: GET_USER_INFO.CLEAR}), [dispatch]);

    const {
        admin: {languageList},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const [isDebugMode, setIsDebugMode] = useState(isDebug());

    useEffect(() => {
        const language = localStorage.getItem(LOCAL_STORAGE_CURRENT_LANGUAGE);

        if (isDebugMode) {
            i18n.changeLanguage('cimode');
        } else if (language) {
            i18n.changeLanguage(language);
        } else {
            const browserLanguage = getBrowserLanguage(languageList, navigator.language);
            i18n.changeLanguage(browserLanguage);
            localStorage.setItem(LOCAL_STORAGE_CURRENT_LANGUAGE, browserLanguage);
        }
    }, [isDebugMode]);

    useEffect(() => {
        const keydownListener = event => {
            if (event.ctrlKey && event.shiftKey && event.key === 'D') {
                const debug = isDebug();
                if (debug) {
                    localStorage.removeItem(LOCAL_STORAGE_DEBUG_MODE);
                } else {
                    localStorage.setItem(LOCAL_STORAGE_DEBUG_MODE, 'true');
                }
                setIsDebugMode(!debug);
            }
        };

        document.addEventListener('keydown', keydownListener);

        return () => document.removeEventListener('keydown', keydownListener);
    }, []);

    useEffect(() => {
        getLanguageList();
        getInstanceSettings();
        getInstanceFileNames();
        getOrganizationInformation();
    }, []);

    useEffect(() => {
        if (get(profile, 'hasCustomAvatar', false)) {
            getAvatar();
        }
    }, [profile]);

    const clearLocalStorage = async () => {
        clearProfile();
        localStorage.removeItem(LOCAL_STORAGE_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_PERSIST_ROOT);
        localStorage.removeItem(LOCAL_STORAGE_LOGOUT_TIME);
        window.location.reload();
    };

    useEffect(() => {
        const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
        const refreshToken = localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN);

        if (token && refreshToken) {
            const difference = getExpirationAndCurrentTimeDifference(String(token));
            if (difference && difference <= 0) {
                clearLocalStorage();
            }
        } else if (refreshToken) {
            clearLocalStorage();
        } else {
            clearProfile();
        }
    }, []);

    useEffect(() => {
        window.addEventListener('swUpdated', e => {
            alert('swUpdated');
        });
    }, []);

    return (
        <MediaContextWrapper>
            <AlertContextWrapper>
                <CryptoContextWrapper>
                    <RouteContextWrapper>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <CssBaseline />
                            <UpdateWatcher />
                            <Routes>
                                <Route path={routes.INVITE} element={<StartInvite />} />
                                <Route path={routes.REDIRECT} element={<LoginRedirect />} />
                                <Route path={routes.FORGET_NICKNAME} element={<ForgetNickname />} />
                                <Route path={routes.RESET_PASSWORD} element={<ResetPassword />} />
                                <Route path={routes.SET_PASSWORD} element={<RecoveryPassword />} />
                                <Route path={routes.RECOVERY_PASSWORD} element={<RecoveryPassword isRecovery />} />
                                <Route path={routes.EMAIL_UPDATE} element={<EmailUpdatePage />} />
                                <Route path={routes.VERIFICATION} element={<Verification />} />
                                <Route path={routes.RULE_ROUTE_IMPRINT} element={<ImprintPage />} />
                                <Route path={routes.RULE_ROUTE_PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
                                <Route path={routes.LOGIN_SSO} element={<LoginSso />} />
                                <Route path={routes.AUTH_SSO} element={<AuthSso />} />
                                <Route path={routes.REGISTRATION_SSO} element={<RegistrationSso />} />
                                <Route path={routes.START_NO_SSO} element={<LoginNoSso />} />

                                {/* Common roles routes */}
                                <Route
                                    path={routes.SELECT_DEFAULT_ROLE}
                                    element={
                                        <PrivateRoute
                                            roles={[userRoles.ROLE_REQUEST, userRoles.ROLE_AGENT, userRoles.ROLE_ADMIN]}
                                        >
                                            <SelectDefaultRolePage />
                                        </PrivateRoute>
                                    }
                                />

                                {/* ROLE_REQUEST routes */}
                                <Route
                                    path={routes.GENERAL}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_REQUEST]}>
                                            <GeneralPage />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.REQUEST}/:requestCase/form/:form`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_REQUEST]}>
                                            <RequesterCasePage />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.REQUEST}/:requestCase/chat`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_REQUEST]}>
                                            <ClientChatPage />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={routes.ACCOUNT}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_REQUEST]}>
                                            <ClientScreenWrapper>
                                                <AccountSettingsPage />
                                            </ClientScreenWrapper>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={routes.SECURITY}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_REQUEST]}>
                                            <ClientScreenWrapper>
                                                <SecurityPage />
                                            </ClientScreenWrapper>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={routes.REQUESTS_LIST}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_REQUEST]}>
                                            <ClientStartPage />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={routes.FORMS_LIST_REQUESTER}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_REQUEST]}>
                                            <ClientFormsListForRequesterPage />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.FINAL_PAGE}/:requestCase/form/:form`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_REQUEST]}>
                                            <ClientFinalPage />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.REQUEST}/:form/faq`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_REQUEST]}>
                                            <FormFaqPage />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.REQUEST}/:requestCase/keysGenerate/:form`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_REQUEST]}>
                                            <CaseKeysGeneration />
                                        </PrivateRoute>
                                    }
                                />

                                {/* ROLE_SUPER_ADMIN routes */}
                                <Route
                                    path={routes.SUPER_ADMIN}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_SUPER_ADMIN]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={routes.SUPER_ADMIN_ACCOUNT}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_SUPER_ADMIN]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={routes.SUPER_ADMIN_SECURITY}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_SUPER_ADMIN]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={routes.ADD_ORGANIZATION}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_SUPER_ADMIN]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.UPDATE_ORGANIZATION}/:id`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_SUPER_ADMIN]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.BOARD}/:uuid/cases`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_ADMIN, userRoles.ROLE_AGENT]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.BOARD_OVERVIEW}/:uuid/cases`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_ADMIN, userRoles.ROLE_AGENT]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.LIST}/:uuid/cases`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_ADMIN, userRoles.ROLE_AGENT]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.WORKFLOW}/:uuid/edit`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_ADMIN, userRoles.ROLE_AGENT]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.WORKFLOW}/:uuid/events`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_ADMIN, userRoles.ROLE_AGENT]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.ROLE}/:id/create`}
                                    element={
                                        <PrivateRoute
                                            roles={[
                                                userRoles.ROLE_SUPER_ADMIN,
                                                userRoles.ROLE_ADMIN,
                                                userRoles.ROLE_AGENT,
                                            ]}
                                        >
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.ROLE}/:uuid/edit`}
                                    element={
                                        <PrivateRoute
                                            roles={[
                                                userRoles.ROLE_SUPER_ADMIN,
                                                userRoles.ROLE_ADMIN,
                                                userRoles.ROLE_AGENT,
                                            ]}
                                        >
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.UPDATE_ORGANIZATION}/:id/roles`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_SUPER_ADMIN]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />

                                {/* ROLE_ADMIN routes */}
                                <Route
                                    path={routes.ADMIN}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_ADMIN]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={routes.ADMIN_ACCOUNT}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_ADMIN]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={routes.ADMIN_SECURITY}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_ADMIN]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.CASE_ACCESS_REQUEST}/:uuid/edit`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_ADMIN, userRoles.ROLE_AGENT]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.RESOURCE_FIELD}/:uuid/edit`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_ADMIN, userRoles.ROLE_AGENT]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.RESOURCE_FIELD}/calendar/:uuid/edit`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_ADMIN, userRoles.ROLE_AGENT]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.BOARD}/create`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_ADMIN, userRoles.ROLE_AGENT]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.FORM}/create`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_ADMIN, userRoles.ROLE_AGENT]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.FORM}/:id/view`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_ADMIN, userRoles.ROLE_AGENT]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.FORM}/:id/edit`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_ADMIN, userRoles.ROLE_AGENT]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.FORM}/:id/emails`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_ADMIN, userRoles.ROLE_AGENT]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.FORM}/:versionUuid/version`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_ADMIN, userRoles.ROLE_AGENT]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.FORM_TRANSLATIONS}/:id/translation/:lang`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_ADMIN, userRoles.ROLE_AGENT]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />

                                {/* ROLE_AGENT routes */}
                                <Route
                                    path={routes.AGENT}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_AGENT]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={routes.AGENT_ACCOUNT}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_AGENT]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={routes.AGENT_SECURITY}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_AGENT]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.REQUESTER_CASE}/:caseId/form/:formId`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_ADMIN, userRoles.ROLE_AGENT]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.REQUESTER_CASE}/:requestCase/chat`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_ADMIN, userRoles.ROLE_AGENT]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.REQUESTER_CASE}/:caseId/activity`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_ADMIN, userRoles.ROLE_AGENT]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={routes.SAVED_FILTERS}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_ADMIN, userRoles.ROLE_AGENT]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.REQUESTER_CASE}/:caseId/form/:formId/print`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_ADMIN, userRoles.ROLE_AGENT]}>
                                            <PrintView />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.REQUESTER_CASE}/:caseId/form/:formId/pdfexport`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_ADMIN, userRoles.ROLE_AGENT]}>
                                            <PdfExportView />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.DASHBOARD}/:uuid/panels`}
                                    element={
                                        <PrivateRoute roles={[userRoles.ROLE_ADMIN, userRoles.ROLE_AGENT]}>
                                            <AgentScreen />
                                        </PrivateRoute>
                                    }
                                />

                                <Route
                                    path={`${paths.REQUESTER_CASE}/*`}
                                    element={<Redirect to={routes.REQUESTER_CASE} />}
                                />
                                <Route path={`${paths.INVITE}/*`} element={<Redirect to={routes.INVITE} />} />
                                <Route path={`${paths.REQUEST}/*`} element={<Redirect to={routes.REQUEST} />} />
                                <Route path={`${paths.AUTH_SSO}/*`} element={<Redirect to={routes.AUTH_SSO} />} />
                                <Route
                                    path={`${paths.REGISTRATION_SSO}/*`}
                                    element={<Redirect to={routes.REGISTRATION_SSO} />}
                                />
                                <Route path={`${paths.LOGIN_SSO}/*`} element={<LoginSso />} />
                                <Route path={`${paths.START_NO_SSO}/*`} element={<LoginNoSso />} />
                                <Route path={`${paths.FORM}/*`} element={<Redirect to={''} />} />

                                <Route path={`/:form`} element={<Start />} />
                                <Route path={routes.START} element={<Login />} />
                                <Route path="/api/*" element={<ApiComponent />} />
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </MuiPickersUtilsProvider>
                    </RouteContextWrapper>
                </CryptoContextWrapper>
            </AlertContextWrapper>
        </MediaContextWrapper>
    );
};

export default App;
