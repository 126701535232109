import React, {FC} from 'react';

import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Badge from '@mui/material/Badge';

import {theme, MAIN_CLIENT_COLOR} from 'config/theme';

interface AScrollChatToBottomType {
    notReadMessages: number | null;
    onClickAction: () => void;
}

const ScrollChatToBottom: FC<AScrollChatToBottomType> = ({onClickAction, notReadMessages}) => {
    return (
        <IconButton
            sx={{
                zIndex: 999999,
                position: 'fixed',
                right: 50,
                bottom: 100,
                backgroundColor: theme.palette.info.main,
                '&:hover': {
                    backgroundColor: theme.palette.info.main,
                },
            }}
            onClick={onClickAction}
        >
            <Badge
                badgeContent={notReadMessages}
                invisible={!notReadMessages}
                sx={{
                    mt: '2px',
                    '& .MuiBadge-badge': {
                        top: -4,
                        right: -4,
                        backgroundColor: MAIN_CLIENT_COLOR,
                        color: theme.palette.background.default,
                    },
                }}
            >
                <ExpandMoreIcon sx={{color: theme.palette.background.default}} />
            </Badge>
        </IconButton>
    );
};

export default ScrollChatToBottom;
