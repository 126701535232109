import React, {FC, useEffect, useState, ReactNode, useCallback, useContext} from 'react';
import get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';

import {GET_NEW_CHAT_MESSAGES, CHAT_AGENT_CONTACTS} from 'appRedux/actions/requestChat';
import {GET_ALL_REQUESTER_CASES, GET_REQUESTER_CASE} from 'appRedux/actions/requestCase';
import {RootReducer} from 'appRedux/reducers';

import {WebsocketContext, WebsocketContextType, WEBSOCKET_PING_INTERVAL} from 'contexts/websocket/context';
import {RouteContext} from 'contexts/route/context';

import {LOCAL_STORAGE_TOKEN} from 'services/localStorage';

import {WEBSOCKET_NOTIFICATION_NEW_CHAT_MESSAGE} from 'config/index';

interface ContextType {
    children: ReactNode;
}

const WebsocketProviderWrapper: FC<ContextType> = ({children}) => {
    const dispatch = useDispatch();

    const {
        isAdminPage,
        isAgentPage,
        isClientMode,
        isCurrentUserRequesterCasesListPage,
        isRequesterFormPage,
        isAgentChatPage,
        isRequesterCaseInfoPage,
        isRequesterChatPage,
    } = useContext(RouteContext);

    const [notReadMessagesCounter, setNotReadMessagesCounter] = useState<number>(0);
    const [isShowScrollBottomButton, setIsShowScrollBottomButton] = useState<boolean>(false);

    const updateMessagesCounter = () => {
        setNotReadMessagesCounter(previous => previous + 1);
    };

    const getChatContacts = useCallback(() => dispatch({type: CHAT_AGENT_CONTACTS.REQUEST_BACKGROUND}), [dispatch]);

    const getNewChatMessages = useCallback(
        data => dispatch({type: GET_NEW_CHAT_MESSAGES.REQUEST_BACKGROUND, payload: data}),
        [dispatch],
    );

    const getAllRequesterCases = useCallback(
        data => dispatch({type: GET_ALL_REQUESTER_CASES.REQUEST_BACKGROUND, payload: data}),
        [dispatch],
    );

    const getRequestCaseInformation = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE.REQUEST_BACKGROUND, payload: data}),
        [dispatch],
    );

    const {
        profile,
        requestChat,
        requestCase: {
            currentCase: {id: caseId},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        const socket = new WebSocket(`${process.env.REACT_APP_WS_ENDPOINT}`);

        socket.onopen = () => {
            console.log('WebSocket opened');
            const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
            if (token) {
                socket.send(token);
                console.log('WebSocket token is sent');
                setInterval(function () {
                    if (socket.readyState === WebSocket.OPEN) {
                        socket.send(JSON.stringify({type: 'ping'}));
                    }
                }, WEBSOCKET_PING_INTERVAL);
            }
        };

        socket.onclose = () => {
            console.log('WebSocket closed');
        };

        socket.onmessage = event => {
            const message = event.data;
            console.log('WebSocket message received:', message);
            if (message === WEBSOCKET_NOTIFICATION_NEW_CHAT_MESSAGE) {
                if (isAdminPage || isAgentPage) {
                    updateChatContacts();
                }
                if (isAgentChatPage || isRequesterChatPage || isRequesterCaseInfoPage) {
                    updateChatMessages();
                }
                if (isClientMode || isCurrentUserRequesterCasesListPage) {
                    const userId = get(profile, ['profile', 'id'], null);
                    getAllRequesterCases({
                        id: userId,
                    });
                }
                if (isRequesterFormPage) {
                    getRequestCaseInformation({id: caseId});
                }
            }
        };

        socket.onerror = error => {
            console.log('WebSocket error:', error);
        };

        return () => {
            socket.close();
        };
    }, []);

    const updateChatContacts = () => {
        getChatContacts();
    };

    const updateChatMessages = () => {
        const newestMessageId: string = get(requestChat, 'newestMessageId', '');
        if (newestMessageId && caseId) {
            getNewChatMessages({
                id: caseId,
                lastMessageUuid: newestMessageId,
                callback: () => {
                    setIsShowScrollBottomButton(true);
                    updateMessagesCounter();
                },
            });
        }
    };

    const context: WebsocketContextType = {
        isShowScrollBottomButton,
        setIsShowScrollBottomButton,
        notReadMessagesCounter,
        setNotReadMessagesCounter,
    };

    return <WebsocketContext.Provider value={context}>{children}</WebsocketContext.Provider>;
};

export default WebsocketProviderWrapper;
