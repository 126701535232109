import {FormFieldTypes, FormSectionTypes, FieldOptionType} from 'appRedux/actions/forms/types';
import {RequesterCaseItemType} from 'appRedux/actions/requestCase/types';

import {getInitialFieldValue} from 'pages/client/form/helper';

import {OPTIONS_SEPARATOR} from 'pages/admin/updateForm/partials/FormStructure/helper';

export const getNotSelectedRelatedSectionsIds = (
    sections: FormSectionTypes[],
    pageId: number,
    result: RequesterCaseItemType,
): number[] => {
    const ids: number[] = [];
    const allRelatedSections: number[] = [];
    const selectedRelatedSections: number[] = [];

    sections.forEach((section: FormSectionTypes) => {
        const {fields} = section;
        if (fields && fields.length > 0) {
            fields.forEach((field: FormFieldTypes) => {
                const {fieldOptions} = field;
                if (fieldOptions && fieldOptions.length > 0) {
                    fieldOptions.forEach((option: FieldOptionType) => {
                        const {relatedSection, id} = option;
                        if (relatedSection) {
                            allRelatedSections.push(relatedSection);

                            const selectedOptionsString = getInitialFieldValue(pageId, section.id, field.id, result);
                            const selectedOptions: string[] = selectedOptionsString
                                ? selectedOptionsString.split(OPTIONS_SEPARATOR)
                                : [];

                            selectedOptions.forEach(selectedOption => {
                                if (Number(selectedOption) === id) {
                                    selectedRelatedSections.push(relatedSection);
                                }
                            });
                        }
                    });
                }
            });
        }
    });

    allRelatedSections.forEach(item => {
        if (!selectedRelatedSections.includes(item)) {
            ids.push(item);
        }
    });

    return ids;
};
