import * as yup from 'yup';
import {TFunction} from 'react-i18next';

import {
    WorkflowStatusRuleApproversGroupItemTypes,
    WorkflowStatusRuleApproverItemTypes,
} from 'appRedux/actions/workflow/types';

import {MAXIMAL_UPLOAD_FILE_SIZE, SUPPORTED_FORMATS} from 'pages/client/form/fieldTypes/validation';
import {
    WORKFLOW_EVENT_WEBHOOK_LEAVE_STATUS,
    WORKFLOW_EVENT_WEBHOOK_CHANGE_TO_STATUS,
} from 'pages/admin/updateWorkflowEvents/helper';

export const TAB_FOR_ORG_USER = 1;
export const TAB_FOR_REQUESTER = 2;

export const initialValues = {
    title: '',
};

export const validationSchemaCreate = (t: (trans: string) => string) =>
    yup.object({
        title: yup.string().required(t('messages.validation.isRequired')),
    });

export const validationSchemaWorkflowStatusEvent = (t: (trans: string) => string) =>
    yup.object({
        text: yup.string().required(t('messages.validation.isRequired')),
    });

export const validationSchemaWorkflowStatusEventForEmail = (t: (trans: string) => string) =>
    yup.object({
        subject: yup.string().required(t('messages.validation.isRequired')),
        text: yup.string().required(t('messages.validation.isRequired')),
    });

export const validationSchemaWorkflowStatusEventForWebhook = (t: (trans: string) => string) =>
    yup.object({
        url: yup.string().required(t('messages.validation.isRequired')),
        text: yup.string().required(t('messages.validation.isRequired')),
    });

export const validationSchemaWorkflowTemplate = (t: (trans: string) => string) =>
    yup.object({
        text: yup.string().required(t('messages.validation.isRequired')),
    });

export const validationSchemaWorkflowTemplateForEmail = (t: (trans: string) => string) =>
    yup.object({
        subject: yup.string().required(t('messages.validation.isRequired')),
        text: yup.string().required(t('messages.validation.isRequired')),
    });

export const validationSchemaWorkflowStatusTranslation = (t: (trans: string) => string) =>
    yup.object({
        translation: yup.string().required(t('messages.validation.isRequired')),
    });

export const validationSchemaWorkflowStatusTranslationRequester = (t: (trans: string) => string) =>
    yup.object({
        translationRequester: yup.string().required(t('messages.validation.isRequired')),
    });

export const validationSchemaWorkflowAttachment = (t: (trans: string) => string) =>
    yup.object({
        title: yup.string().required(t('messages.validation.isRequired')),
        file: yup
            .mixed()
            .test('fileSize', t('messages.error.wrongDocumentSizeMessage'), value => {
                return value ? value.size <= MAXIMAL_UPLOAD_FILE_SIZE : true;
            })
            .test('fileFormat', t('messages.error.wrongDocumentTypeMessage'), value => {
                return value ? SUPPORTED_FORMATS.includes(value.type) : true;
            }),
    });

export const validationSchemaWorkflowRuleOptions = (
    t: (trans: string) => string,
    groups: WorkflowStatusRuleApproversGroupItemTypes[],
    approvers: WorkflowStatusRuleApproverItemTypes[],
) =>
    yup.object({
        requiredApproval: yup
            .number()
            .typeError(t('messages.validation.isNumberRequired'))
            .required(t('messages.validation.isRequired')),
    });

export const getValidationSchemaType = (t: TFunction, type: number, forEmailTemplate?: boolean): any => {
    if (forEmailTemplate) {
        return validationSchemaWorkflowStatusEventForEmail(t);
    }
    if (type === WORKFLOW_EVENT_WEBHOOK_LEAVE_STATUS || type === WORKFLOW_EVENT_WEBHOOK_CHANGE_TO_STATUS) {
        return validationSchemaWorkflowStatusEventForWebhook(t);
    }
    return validationSchemaWorkflowStatusEvent(t);
};

export const validationSchemaWorkflowTask = () =>
    yup.object({
        period: yup.number().required('messages.validation.isRequired'),
    });

export const validationSchemaWorkflowTaskEmailTemplate = (t: (trans: string) => string) =>
    yup.object({
        subject: yup.string().required(t('messages.validation.isRequired')),
        text: yup.string().required(t('messages.validation.isRequired')),
    });
